import { useEffect, useState } from "react"
import { useEthers, useContractFunction } from "@usedapp/core"
import { constants, utils } from "ethers"
import CryptoSpot from "../chain-info/contracts/CryptoSpot.json"
import { Contract } from "@ethersproject/contracts"
import networkMapping from "../chain-info/deployments/map.json"

export const useCsGetPrice = (tokenAddress: string) => {
    // address
    // abi
    // chainId
    const { chainId } = useEthers()
    const { abi } = CryptoSpot
    const csAddress = chainId ? networkMapping[String(chainId)]["CryptoSpot"][0] : constants.AddressZero
    const csInterface = new utils.Interface(abi)
    const csContract = new Contract(csAddress, csInterface)

    // mint
    const { send: getPriceSend, state: getPriceState } =
        useContractFunction(csContract, "getPrice", {
            transactionName: "getPrice",
        })

    return { getPriceSend, getPriceState }
}

export default useCsGetPrice
