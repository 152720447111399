import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        { (process.env.REACT_APP_MINT_VISIBLE === 'true') ? 
          <li>
            <a style={{cursor:'pointer'}} onClick={() => openInNewTab("https://rinkeby.etherscan.io/address/0x965f922916557AAD870bdB42c05e13D4B7Ef77A0")}>Smart Contract</a>
          </li>
        :
        ""
        }
        {/*
        <li>
          <Link to="#0">About us</Link>
        </li>
        */}
      </ul>
    </nav>
  );
}

export default FooterNav;