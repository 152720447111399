import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import Button from '../elements/Button';
import { useEthers, useEtherBalance } from "@usedapp/core"
import MintForm from '../yourWallet/MintForm';
//import HeaderSocial from './partials/HeaderSocial';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const { account, chainId, activateBrowserWallet, deactivate } = useEthers()
  //const balanceEth = useEtherBalance(account)
  const isConnected = account !== undefined

  const [isActive, setIsactive] = useState(false);
  const [displayMint, setDisplayMint] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });  

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const toggleMintMenu = () => {
    setDisplayMint(!displayMint);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          <Logo />
          {!hideNav &&
            <div>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">
                  <ul>
                    <div className={classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                      ChainId: {chainId ? chainId : "-" }
                    </div>
                  </ul>
                  <ul>
                    <div className={classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                      Account: {account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : "-" }
                    </div>
                  </ul>
                  <ul className={
                    classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                    { (process.env.REACT_APP_MINT_VISIBLE === 'true') ? 
                    <li>
                      <Link to="#0" onClick={toggleMintMenu}>Mint Menu</Link>
                    </li>
                    :
                    ""
                    }
                  </ul>
                  <ul>
                  {!hideSignin && isConnected ? (
                      <Button
                      color="dark"
                      wideMobile
                      onClick={deactivate}>
                          DISCONNECT
                      </Button>
                  ) : (
                      <Button
                        color="primary"
                        wideMobile
                        onClick={() => activateBrowserWallet()}
                      >
                          CONNECT
                      </Button>
                  )}
                  </ul>
                </div>
              </nav>
            </div>}
        </div>
      {/*<HeaderSocial />*/}
      { (process.env.REACT_APP_MINT_VISIBLE === 'true') ? 
        <MintForm style={displayMint ? {display: "inline"} : {display: "none"} }/>
        :
        ""
      }
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
