import React, { useState, useEffect } from "react"
import { useEthers, useTokenBalance, useNotifications } from "@usedapp/core"
import { formatUnits } from "@ethersproject/units"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { useCryptoSpotMint } from "../../hooks/useCryptoSpotMint"
import { useCsGetPrice } from "../../hooks/useCsGetPrice"
import { utils } from "ethers"
import networkMapping from "../../chain-info/deployments/map.json"
import { constants } from "ethers"

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';

const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
  }
  
  const defaultProps = {
    ...SectionProps.defaults,
    split: false
  }
  
export const MintForm = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props
  }) => {

    const outerClasses = classNames(
        'cta section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    
      const innerClasses = classNames(
        'cta-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
      );  


    const { account, chainId } = useEthers()
    const { notifications } = useNotifications()

    const isConnected = account !== undefined

    const tokenAddress =  chainId ? networkMapping[chainId]["CryptoSpot"][0] : constants.AddressZero 
    const tokenBalance = useTokenBalance(tokenAddress, account)

    const [amount, setAmount] = useState(2)

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = event.target.value === "" ? "" : Number(event.target.value)
        setAmount(newAmount)
    }

    const { mintSend, mintState } = useCryptoSpotMint(tokenAddress)
    const { getPriceSend, getPriceState } = useCsGetPrice(tokenAddress)

    /*const price = getPriceSend()
    console.log("price = ", price)*/

    const handleMintSubmit = () => {
        if(isConnected) {
            // integer operations instead of float required (0.15 = 15 /100)
            const value = utils.parseEther(((amount * 15)/100).toString())
            return mintSend(account, amount, { value: value})
        } else {
            setConnectException(true)
        }
    }

    const isMining = mintState.status === "Mining"
    const [showMintSuccess, setMintSuccess] = useState(false)
    const [showMintException, setMintException] = useState(false)
    const [showConnectException, setConnectException] = useState(false)  

    const handleCloseSnack = () => {
        setMintSuccess(false)
    }

    const handleCloseExceptionSnack = () => {
        setMintException(false)
    }

    const handleCloseConnectSnack = () => {
        setConnectException(false)
    }

    useEffect(() => {
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Mint").length > 0) {
            setMintSuccess(true)
        }
        if (mintState.status === "Exception") {
            setMintException(true)
            console.log("transactionFailed")
        }
    }, [notifications, showMintSuccess, mintState])

    // TODO - FIXME. This was for useStyles, all {classes.NNN} need to be replaced
    const classes = false

    return (
    <section
        {...props}
        className={outerClasses}
    >

        <div className="container" >
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">Let's mint some CryptoSpot!</h3>
            <p>Because we likes it</p>
          </div>
                <div>
                    <Input
                        type="number"
                        min="1"
                        max="10"
                        maxLength="2"
                        value={amount}
                        onChange={handleInputChange} />
                    <Button
                        onClick={handleMintSubmit}
                        color="primary"
                        size="large"
                        variant="contained"
                        disabled={isMining}
                        loading={isMining ? true: false}
                        >
                        MINT
                    </Button>
                </div>
                <Snackbar
                    open={showMintSuccess}
                    autoHideDuration={5000}
                    onClose={handleCloseSnack}
                >
                    <Alert onClose={handleCloseSnack} severity="success">
                        Successfuly minted. Welcome to CryptoSpot.
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={showMintException}
                    autoHideDuration={5000}
                    onClose={handleCloseExceptionSnack}
                >
                    <Alert onClose={handleCloseExceptionSnack} severity="error">
                        Mint exception: {mintState.errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={showConnectException}
                    autoHideDuration={5000}
                    onClose={handleCloseConnectSnack}
                >
                    <Alert onClose={handleCloseConnectSnack} severity="error">
                        Please connect your wallet first.
                    </Alert>
                </Snackbar>
            </div>
        </div>
    </section>
    )
}

MintForm.propTypes = propTypes;
MintForm.defaultProps = defaultProps;

export default MintForm
