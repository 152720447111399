import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Real Life Meets The Metaverse',
    paragraph: 'The CryptoSpot NFT combines the real world and the Metaverse. Use your digital NFT deck to battle in exclusive online tournaments, or your casino quality cards for real life games against friends and family.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Fun
                  </div>
                <h3 className="mt-0 mb-12">
                  Learn The Game, Win the Game
                  </h3>
                <p className="m-0">
                  A CryptoSpot deck is composed of 57 cards. Each card is unique and has eight cryptocurrency logos on it. Take any two cards in the deck and there will be exactly one cryptocurrency logo that both cards share. Spot the identical cryptocurrency, name it and win the game. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/banner.jpg')}
                  alt="CryptoSpot deck"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Unique
                  </div>
                <h3 className="mt-0 mb-12">
                  More Unique than the Universe
                  </h3>
                <p className="m-0">
                The combination of art, maths and gaming, every CryptoSpot deck is beautiful, playable and unique. How unique? There are more than 7.74 x 10<sup>69</sup> possible combinations of decks. Much, much more than there are stars in the universe. We chose 10,042 designs.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/stars.jpg')}
                  alt="CryptoSpot deck"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Smart
                  </div>
                <h3 className="mt-0 mb-12">
                  The Maths
                  </h3>
                <p className="m-0">
                Sit tight, here comes the maths. The game works because of an Italian mathematician called Gino Fano and the Fano Plane, a branch of projective finite geometry.
                Mind bending stuff. Gino’s maths makes it possible to have 57 cards with eight symbols where there is always one (and only one) matching symbol on any two cards.
                Join Discord to learn more about the Maths!
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Gino_glasses_square.jpg')}
                  alt="CryptoSpot deck"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Benefits
                  </div>
                <h3 className="mt-0 mb-12">
                  Your game, your community
                  </h3>
                <p className="m-0">
                  <span className="text-color-high">Rewards - </span>We will create a treasury. It will be used to fund the roadmap, reward prizes and gifts, and, should the community decide, buy the floor. Community voting will decide on what else to spend the treasury.
                </p>
                <p className="m-0">
                  <span className="text-color-high">Tournaments - </span>The Premier League of competitive CryptoSpot. Play, stake, win, battle. Tournaments will be a spectacle, both in the Metaverse and in real life. From low stakes to high rollers, every buy-in will be available.
                </p>
                <p className="m-0">
                  <span className="text-color-high">Meetups and events - </span>Tournaments, meetups, parties and celebrations, both in the real world and the Metaverse. The community will decide on when and where. 
                </p>                

              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/community2.jpg')}
                  alt="Community"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;