import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const GameRules = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Game Rules',
    paragraph: 'CryptoSpot is a fast moving card game for 2 or more players. Be the first to spot the matching cryptocurrency and name it, win the cards.'
  };

  const sectionSubHeader = {
    title: 'The Game Variants',
    paragraph: 'There are 5 game variants to choose from: HODL, Short, Vitalik Dump, Coin Drop and Rug Pull. Each needs a different strategy to come out on top. From collaboration to open skulduggery, choose your friends wisely.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <SectionHeader data={sectionSubHeader} tag='h3' className="center-content" />        
          <div className={tilesClasses}>

          <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
              <div className="testimonial-rules-content text-xs mt-0 mb-16">
                  <span className="text-color-primary">Variant 1</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">HODL</span>
                </div>                
                <div className="testimonial-rules-footer has-top-divider">
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Setup </span>
                  Each player receives one card, face down. The rest of the deck is placed, face up, in a pile in the centre of the playing surface. To start the game, all players turn their card face up at the same time.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Rules </span>
                  If a cryptocurrency symbol on the first card matches one on yours, the first person to name the symbol correctly adds it to their pile.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Goal </span>
                  Win as many cards as possible, and HODL your crypto. The player with the most cards at the end wins.
                  </p>                
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
              <div className="testimonial-rules-content text-xs mt-0 mb-16">
                  <span className="text-color-primary">Variant 2</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Short</span>
                </div>                
                <div className="testimonial-rules-footer has-top-divider">
                <p className="text-xs mb-0">
                <span className="text-color-secondary text-sm">Setup </span>
                Place one card in the middle facing down. Distribute the rest of the cards equally between players. When all cards have been distributed, the center card can be turned over, and the game begins.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Rules </span>
                  Turn the card over in the middle. As soon as you see the same crypto symbol on your top card as on the card in the middle pile, 1) name the symbol, 2) place your card, face up, on top of the pile. 
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Goal </span>
                  Short the crypto. The player with the fewest cards at the end of the game wins.  
                  </p>               
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
              <div className="testimonial-rules-content text-xs mt-0 mb-16">
                  <span className="text-color-primary">Variant 3</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Vitalik Dump</span>
                </div>                
                <div className="testimonial-rules-footer has-top-divider">
                <p className="text-xs mb-0">
                <span className="text-color-secondary text-sm">Setup </span>
                This variant is played with 3 or more players. Give each player one card, face down. Place all other cards in a pile in the center face up.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Rules </span>
                    Find the matching crypto token between the card on the center pile and a card from another player, then ‘dump’ the center card on your <strike>Vitalik</strike> victim. Make alliances with other players, then betray them at the opportunistic moment to win.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Goal </span>
                  Dump all your <strike>SHIB</strike> Shit-coin cards onto other players. The winner is the player with the fewest cards once all have been turned over. 
                  </p>                      
                </div>
              </div>
            </div>

          <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
              <div className="testimonial-rules-content text-xs mt-0 mb-16">
                  <span className="text-color-primary">Variant 4</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Coin Drop</span>
                </div>                
                <div className="testimonial-rules-footer has-top-divider">
                <p className="text-xs mb-0">
                <span className="text-color-secondary text-sm">Setup </span>
                This variant is played in rounds. At the beginning of a round each player starts with one palm facing up. Place one card on each player’s palm facing up.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Rules </span>
                  Find the matching crypto token between your card and one of another’s player, 1) name the crypto token and 2) drop your card facing up on your opponent’s hand.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Goal </span>
                  Drop your coins on the other players. End-up with as few cards as possible and win your upcoming ICO.
                  </p>                
                </div>
              </div>
            </div>

          <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
              <div className="testimonial-rules-content text-xs mt-0 mb-16">
                  <span className="text-color-primary">Variant 5</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-name text-color-high">Rug Pull</span>
                </div>                
                <div className="testimonial-rules-footer has-top-divider">
                <p className="text-xs mb-0">
                <span className="text-color-secondary text-sm">Setup </span>
                This variant is played in rounds. At the beginning of each round give every player one card, face down. Place one card in the center face up. All players turn their cards over at the same time.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Rules </span>
                  Find the matching crypto token between the center pile card and another card in the game, then 1) name the crypto token and 2) pick the card from the other player, and keep it (face down). When all cards in the game have been picked, the center card is returned to the bottom of the remaining pile of cards before starting a new round.
                </p>
                <p className="text-xs mb-0">
                  <span className="text-color-secondary text-sm">Goal </span>
                  You want to finish with as many cards as possible. Pull the rug and steal the cards from the other players. Just don’t touch the card in the centre, that is the first block and you may not steal that one. Steal that and the whole chain will collapse.
                  </p>                
                </div>
              </div>
            </div>                                                       

          </div>
        </div>
      </div>
    </section>
  );
}

GameRules.propTypes = propTypes;
GameRules.defaultProps = defaultProps;

export default GameRules;