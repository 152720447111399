import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our Roadmap',
    paragraph: 'At Mint the real work begins. Hitting our milestones, on time, every time. '
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/roadmap-10.png')}
                    alt="10%"
                    width={64}
                    height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Skin in the game
                    </h4>
                  <p className="m-0 text-sm">
                    We could take the advice of BAYC and pay back our Moms, but we invested our own money. Skin in the game.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/roadmap-20.png')}
                    alt="20%"
                    width={64}
                    height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Community Airdrop
                    </h4>
                  <p className="m-0 text-sm">
                  The first of many airdrops to the community. Five CryptoSpot decks dropped to random NFT holders
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/roadmap-40.png')}
                    alt="40%"
                    width={64}
                    height={64} />
                  </div>
                  </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Let the Games Begin
                    </h4>
                  <p className="m-0 text-sm">
                    We’re mega excited about this. We will finalise the online game and make it fit for tournaments. We will share dev logs, images and footage as we progress. GMI! 
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/roadmap-60.png')}
                    alt="60%"
                    width={64}
                    height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Personalize Decks - The personal touch
                    </h4>
                  <p className="m-0 text-sm">
                  CryptoSpot community members get to personalise the back of their cards with their own NFTs. Own a Mutant Ape, Gambling Ape, Blockart or CryptoPunk? Then have it emblazoned on your cards and flex your NFTS. 
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/roadmap-80.png')}
                    alt="80%"
                    width={64}
                    height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Elite Finishing
                    </h4>
                  <p className="m-0 text-sm">
                  All physical decks with a premium box and a captivating casino standard finish will be made available. The option to add on will be released soon.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/roadmap-100.png')}
                    alt="100%"
                    width={64}
                    height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    NFT Breeding
                    </h4>
                  <p className="m-0 text-sm">
                  CryptoSpot NFT owners can generate a derivative deck based on any 2 Genesis decks they own. Missing your favourite Crypto Tokens? Add them here. 
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;