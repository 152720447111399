import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import AboutSection from '../components/sections/AboutSection';
import GameRules from '../components/sections/GameRules';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" hideDiscord={true}/>
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <FeaturesTiles />
      {/*<AboutSection />*/}
      <GameRules />
    </>
  );
}

export default Home;